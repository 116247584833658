<template>
	<div>
		<h1 class="articletitle">{{ title }}</h1>
		<div v-html="html" ref="html" />
		<modal
			:styles="modalStyle"
			name="complete-section"
			:draggable="true"
			height="auto"
			:adaptive="true"
		>
			<AnswerModal
				:charCount="charCount"
				:onSuccess="onSuccess"
				:hide="hide"
				:discipline="discipline"
				:pageid="pageid"
			/>
		</modal>
	</div>
</template>

<script>
import { obstruct } from "./utils/dom.js";
import { incProgress } from "./utils/db.js";
import AnswerModal from "./AnswerModal.vue";

export default {
	components: {
		AnswerModal,
	},
	props: {
		pageid: String,
		title: String,
		discipline: String,
		html: String,
		sendToc: Function,
		progress: Object,
		sendSectionsNum: Function,
		incDPG: Function,
	},
	name: "Article",
	data() {
		return {
			currentIndex: this.progress.currentIndex || 0,
			// Answer modal
			charCount: 0,
			onSuccess: undefined,
			//
			modalStyle: "border-radius: 10px;",
		};
	},
	methods: {
		getToc() {
			this.sendToc(document.getElementById("toc"));
			document.getElementById("toc").remove();
		},
		clickDone(buttonEl, charCount) {
			this.charCount = charCount;
			this.onSuccess = () => {
				buttonEl.style.borderColor = "var(--beige)";
				buttonEl.style.color = "var(--beige)";
				buttonEl.innerHTML = "completed ✔️";
				const nextIndex = this.currentIndex + 1;
				document.getElementById("pw-wall-" + nextIndex).remove();

				// Inc in db progress
				incProgress(this.pageid, {
					discipline: this.discipline,
					title: this.title,
					lastUpdate: Date.now(),
				});
				this.incDPG();
				this.currentIndex++;
			};
			this.show();
		},
		show() {
			this.$modal.show("complete-section");
		},
		hide() {
			this.$modal.hide("complete-section");
		},
	},
	mounted() {
		this.getToc();
		const sectionsNum = obstruct(
			document,
			this.clickDone,
			this.progress.currentIndex
		);
		this.sendSectionsNum(sectionsNum);
	},
};
</script>

<style scoped>
@import "./css/article.css";
</style>
