export function obstruct(document, clickDone, currentIndex) {
	if (!currentIndex) currentIndex = 0;
	const collection = document.getElementsByClassName("mw-parser-output")[0]
		.children;

	let i = 0;
	let sIndex = 0;
	while (i++ < collection.length) {
		const el = collection[i];
		if (el) {
			const charCount = el.innerText.trim().length;
			const isSection =
				(el.tagName === "P" || el.tagName === "UL") && charCount > 100;

			if (isSection) {
				if (currentIndex === sIndex) {
					addDoneButton({ sIndex, el, charCount, clickDone, document });
				} else if (currentIndex > sIndex) {
					addCompletedButton({ sIndex, el, charCount, clickDone, document });
				} else if (currentIndex < sIndex) {
					addDoneButton({ sIndex, el, charCount, clickDone, document });

					const wall = document.createElement("span");
					wall.id = "pw-wall-" + sIndex;
					wall.style = wallStyle;

					const lockText = createButton({
						text: "complete previous sections to unlock 🔒",
						document,
						buttonStyle,
						isLink: true,
					});

					wall.appendChild(lockText);
					el.appendChild(wall);
					el.id = "pw-section-" + sIndex;
					el.style = pStyle;
				}
				sIndex++;
			}
		}
	}
	return sIndex;
}

function createButton({
	action,
	text,
	document,
	iconSrc,
	buttonStyle,
	iconStyle,
	isLink,
	id,
}) {
	const button = document.createElement(isLink ? "a" : "button");
	if (action) button.onclick = action;
	const buttonText = document.createTextNode(text);
	button.appendChild(buttonText);
	button.style = buttonStyle;
	if (id) button.id = id;
	if (iconSrc) {
		const icon = document.createElement("img");
		icon.src = iconSrc;
		icon.style = iconStyle;
		button.appendChild(icon);
	}
	return button;
}

function addCompletedButton({ sIndex, el, charCount, clickDone, document }) {
	const doneButton = createButton({
		text: "completed ✔️",
		id: "pw-sec-done-" + sIndex,
		document,
		buttonStyle: buttonStyle + doneButtonStyle + completedStyle,
	});
	doneButton.onclick = () => clickDone(doneButton, charCount);
	el.appendChild(doneButton);
}

function addDoneButton({ sIndex, el, charCount, clickDone, document }) {
	const doneButton = createButton({
		text: "done 👍",
		id: "pw-sec-done-" + sIndex,
		document,
		buttonStyle: buttonStyle + doneButtonStyle,
	});
	doneButton.onclick = () => clickDone(doneButton, charCount);
	el.appendChild(doneButton);
}

const pStyle = "position: relative; overflow: visible; ";
const wallStyle = `border-radius: 8px; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: var(--lightbeige); overflow: auto; display: flex; align-items: center; justify-content: center; padding-bottom: 4px;`;
const buttonStyle =
	"display: flex; align-items: center; justify-content: space-evenly; font-size: 22px; border-radius: 50px; color: var(--brown); background-color: transparent; border: 0;";
const doneButtonStyle =
	"border: 2px solid var(--brown);  border-radius: 2px; margin: 8px 4px 8px auto;";
const completedStyle = "color: var(--beige); border-color: var(--beige);";
