<template>
	<div class="parent">
		<h3>Summarize what you just read</h3>
		<p>(min {{ minChar }} char)</p>
		<textarea
			placeholder="Just a few words for active learning..."
			autofocus
			v-model="answer"
		/>
		<div class="iconRow">
			<p class="errorMessage" :class="{ success }">{{ error || success }}</p>
			<div class="iconContainer">
				<v-icon
					v-if="answer.trim().length >= minChar"
					@click="validate"
					name="arrow-right"
					class="iconArrow"
					scale="2.5"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { saveMessage } from "./utils/db.js";
export default {
	props: {
		charCount: Number,
		onSuccess: Function,
		hide: Function,
		discipline: String,
		pageid: String,
	},
	data() {
		return { error: "", success: "", answer: "" };
	},
	computed: {
		minChar() {
			return Math.round(this.charCount / 10);
		},
	},
	methods: {
		setMess(mes, type) {
			if (type === "error") this.error = mes;
			if (type === "success") this.success = mes;
			setTimeout(() => {
				this.error = "";
				this.success = "";
			}, 3000);
		},
		validate() {
			if (this.minChar > this.answer.length) {
				this.setMess("Your answer is too short.", "error");
			} else {
				this.setMess("+5 XP in " + this.discipline, "success");
				saveMessage({
					message: this.answer,
					discipline: this.discipline,
					pageid: this.pageid,
				});
				this.onSuccess();
				setTimeout(() => this.hide(), 1000);
			}
		},
	},
};
</script>

<style scoped>
.parent {
	padding: 8px;
}
.parent > h3 {
	cursor: grab;
}
.parent > p {
	cursor: grab;
}
h3 {
	margin: 16px;
	text-align: left;
	margin-bottom: 0px;
}
p {
	color: var(--brown);
	margin: 16px;
	margin-top: 0;
}
.errorMessage {
	margin: 16px;
	color: crimson;
}
.success {
	color: green;
}
textarea {
	border: 0;
	outline: none;
	width: calc(100% - 2 * 16px);
	height: 80%;
	padding: 16px;
	font-size: 20px;
	resize: none;
	min-height: 300px;
}
.iconRow {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.iconContainer {
	padding: 32px;
}
.iconArrow {
	margin-right: 8px;
	cursor: pointer;
	color: var(--brown);
	margin-left: auto;
}
</style>
