export function guessDiscipline(html) {
	let counts = {};
	disciplines.forEach((d) => {
		const regex = new RegExp(d.toLowerCase(), "g");
		counts[d] = [...html.toLowerCase().matchAll(regex)].length;
	});
	const leaderboard = Object.keys(counts)
		.map((d) => ({ name: d, count: counts[d] }))
		.sort((a, b) => b.count - a.count);
	let guess = leaderboard[0].name;
	if (guess === "Law") {
		if (counts.Physics > 30) return "Physics";
	}
	return guess;
}

let disciplines = [
	//Humanities
	"Performing arts",
	"Visual arts",
	"History",
	"Languages",
	"Law",
	"Philosophy",
	"Theology",

	//Social science
	"Anthropology",
	"Economics",
	"Geography",
	"Political science",
	"Psychology",
	"Sociology",
	"Social work",

	//Natural science
	"Biology",
	"Chemistry",
	"Earth science",
	"Space science",
	"Physics",

	//Formal science
	"Computer science",
	"Mathematics",

	//Applied science
	"Business",
	"Engineering and technology",
	"Medicine and health",
];
