export async function getHTML(page) {
	const url =
		"https://en.wikipedia.org/w/api.php?" +
		new URLSearchParams({
			origin: "*",
			action: "parse",
			page,
			format: "json",
			redirects: true,
		});

	try {
		const req = await fetch(url);
		const json = await req.json();
		const {
			text,
			displaytitle,
			title,
			langlinks,
			sections,
			pageid,
			categories,
			links,
		} = json.parse;
		return {
			html: text["*"],
			displaytitle,
			title,
			langlinks,
			sections,
			pageid,
			categories,
			links,
		};
	} catch (e) {
		console.error(e);
	}
}
