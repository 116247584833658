<template>
	<div class="bar-container ">
		<div class="bar">
			<div class="logoRow" @click="$router.push('/')">
				<img src="@/assets/logo.svg" class="logoSVG" />
				<div class="logoText">Playful Wiki</div>
			</div>

			<div class="wikiRow" @click="openOriginal">
				<img src="@/assets/wiki.png" class="wikipediaLogo" />
				<div class="wikipediaText">Original article</div>
			</div>

			<div v-if="isLoggedIn !== undefined">
				<div v-if="!isLoggedIn" :key="logKey">
					<p v-if="logStatus === 'virgin'">
						<a href="#" @click="logStatus = 'login'"> Login</a> to save progress
					</p>
					<div v-else-if="logStatus === 'login'" class="loginCol">
						<input placeholder="Email" v-model="email" type="email" />
						<input placeholder="Password" v-model="psw" type="password" />
						<button @click="signIn">Create / Login</button>
					</div>
				</div>
				<div v-else>
					<p class="loggedInText">
						{{ user_email }} <a href="#" @click="logout">Logout</a>
					</p>
				</div>
			</div>
			<h2 v-if="ready">
				{{ discipline }}
				<span style="color: grey; font-size: var(--medium)">(guess)</span>
			</h2>
			<ProgressBar
				v-if="disciplinePG !== undefined"
				type="discipline"
				:pg="disciplinePG"
				:discipline="discipline"
				:key="pgKey + '-1'"
			/>
			<h1>
				{{ title }}
			</h1>
			<ProgressBar
				v-if="progress && sectionsTotal !== undefined"
				type="sections"
				:pg="progress.currentIndex || 0"
				:sectionsTotal="sectionsTotal"
				:key="pgKey + '-2'"
			/>

			<div id="side_toc"></div>
		</div>
	</div>
</template>

<script>
import { auth, emailCred } from "@/firebase";
import { tocStylesObj } from "./domStyles.js";
import ProgressBar from "./ProgressBar.vue";

export default {
	props: {
		pageid: String,
		discipline: String,
		title: String,
		toc: HTMLDivElement,
		progress: Object,
		disciplinePG: Number,
		sectionsTotal: Number,
		pgKey: Number,
	},
	components: {
		ProgressBar,
	},
	data() {
		return {
			psw: "",
			email: "",
			logStatus: "virgin",
			logKey: 0,
		};
	},
	computed: {
		isLoggedIn() {
			this.logKey;
			return auth.currentUser && !auth.currentUser.isAnonymous;
		},
		user_email() {
			return auth.currentUser.email;
		},
		ready() {
			return this.title !== "" && this.discipline !== "";
		},
	},
	watch: {
		toc(value) {
			if (value) {
				const el = document.getElementById("side_toc");
				el.append(value);
				el.style = tocStylesObj.side_toc;
				document.getElementById("mw-toc-heading").remove();
				el.getElementsByTagName("UL")[0].style = tocStylesObj.ul;
			}
		},
	},
	methods: {
		signIn() {
			if (!this.email.trim() || !this.psw.trim() || this.psw.trim().length < 4)
				return;

			const onSuccess = () => this.logKey++;
			var credential = emailCred(this.email, this.psw);
			auth.currentUser
				.linkWithCredential(credential)
				.then((usercred) => {
					console.log("Successful linking and account creation!");
					onSuccess();
				})
				.catch((error) => {
					if (error.code === "auth/email-already-in-use") {
						auth
							.signInWithEmailAndPassword(this.email, this.psw)
							.then(onSuccess);
					}
				});
		},
		logout() {
			auth.signOut().then(() => {
				this.logStatus = "virgin";
				this.logKey++;
			});
		},
		openOriginal() {
			const page = this.$route.params.page;
			open("https://en.wikipedia.org/wiki/" + page, "_blank");
		},
	},
};
</script>

<style scoped>
@import "./common.css";
/* PlayfulWiki logo link */
.logoRow {
	cursor: pointer;
	display: flex;
	align-items: center;
	flex-direction: row;
}
.logoSVG {
	width: 66px;
}
.logoText {
	padding: 8px 8px 8px 16px;
	color: var(--brown);
	font-size: var(--large);
}

/* Wiki link */
.wikiRow {
	margin: 25px 0 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.wikipediaLogo {
	width: 30px;
}
.wikipediaText {
	font-weight: 100;
	margin-left: 12px;
	padding-bottom: 3px;
}
.wikipediaText:hover {
	color: var(--brown);
	padding-bottom: 2px;
	border-bottom: 1px solid var(--brown);
}
/* Login */
.loginCol {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0 10% 0 0;
}
.loggedInText {
	font-size: var(--small);
	margin: 10px 0;
}

ul {
	padding-left: 0;
}
input,
button {
	margin: 6px 0;
}
div {
	--bar-width: 25vw;
}
.bar-container {
	min-width: var(--bar-width);
	width: var(--bar-width);
	height: 100vh;
}
.bar {
	overflow: auto !important;
	background-color: var(--lightbeige);
	position: fixed;
	left: 0;
	height: 100vh;
	min-width: var(--bar-width);
	width: var(--bar-width);
	padding: 8px;
	text-align: left;
	padding-left: 20px;
	border-right: 4px solid var(--beige);
}

@media only screen and (max-width: 600px) {
	.bar,
	.bar-container {
		display: none;
	}
}
</style>
