<template>
	<div class="app">
		<SideBar
			:pageid="pageid"
			:discipline="discipline"
			:title="title"
			:toc="toc"
			:progress="progress"
			:sectionsTotal="sectionsTotal"
			:disciplinePG="disciplinePG"
			:pgKey="pgKey"
		/>
		<Article
			v-if="html && progress"
			:pageid="pageid"
			:html="html"
			:title="title"
			:sendToc="(toc) => (this.toc = toc)"
			:progress="progress"
			:discipline="discipline"
			:incDPG="incDPG"
			:sendSectionsNum="(count) => (sectionsTotal = count)"
		/>
	</div>
</template>

<script>
import Article from "@/wiki/Article.vue";
import SideBar from "@/common/SideBar.vue";
import { guessDiscipline } from "./utils/context.js";
import { getHTML } from "./utils/wikipedia.js";
import { getProgress, getDPG, incDPG } from "./utils/db.js";

export default {
	name: "Wiki",
	components: {
		Article,
		SideBar,
	},
	data() {
		return {
			pageid: undefined,
			title: "",
			discipline: "",
			html: null,
			toc: null,
			progress: undefined,
			disciplinePG: undefined,
			sectionsTotal: undefined,
			pgKey: 0,
		};
	},
	mounted() {
		const page = this.$route.params.page;
		if (!page) return;

		getHTML(page).then((res) => {
			const {
				displaytitle,
				title,
				langlinks,
				sections,
				pageid,
				categories,
				links,
				html,
			} = res;
			this.html = html;

			const guess = guessDiscipline(html);
			this.discipline = guess;
			this.pageid = pageid.toString();
			getDPG(guess).then((res) => {
				this.disciplinePG = res;
			});

			getProgress(pageid.toString()).then((res) => {
				if (res.currentIndex) this.progress = res;
				else this.progress = { id: pageid.toString(), currentIndex: 0 };
			});
			this.title = displaytitle || title;
		});
	},
	methods: {
		incDPG() {
			this.disciplinePG += 5;
			this.progress.currentIndex++;
			incDPG(this.discipline);
			this.pgKey++;
		},
	},
};
</script>

<style scoped>
.app {
	display: flex;
}
</style>
